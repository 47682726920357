import Head from "next/head";
import Image from "next/image";
import Link from "next/link";
import BackButton from "../components/BackButton";
import { useState, useEffect } from "react";
import Course1 from "../images/course-1.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import teacher1 from "../images/teacher1.png";
import biology from "../images/biology-class.png";
import { strapi_end_point } from "../config";
const qs = require("qs");
import he from "he";
import { checkLogin } from "../utils/handle-session";
const CourseflixPage = () => {
  const [query, setQuery] = useState("");
  const tags = ["Python", "Excel", "Data Science", "Marketing"];
  const [programs, setProgram] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [threads, setThreads] = useState([]);
  const [courses, setCourses] = useState([]);
  const [oneHourCourses, setOneHourCourses] = useState([]);
  const [experts, setExperts] = useState([]);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      window.location.href = "https://padhaai.gurucool.xyz/courses?q=" + query;
    }
  };

  const myLoader = ({ src }) => {
    return `${src}`;
  };

  const htmlDecode = (input) => {
    var e = document.createElement("textarea");
    e.innerHTML = input;
    // handle case of empty input
    return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
  };

  const getInitData = async () => {
    const queryForCourses = qs.stringify(
      {
        // filters:{
        //   featured:true,
        // },
        fields: [
          "id",
          "title",
          "price",
          "is_free",
          "by",
          "featured",
          "career_listed",
        ],
        populate: {
          image: "*",
          padhaai_program_courses: {
            fields: ["id"],
          },
        },
        pagination: {
          pageSize: 25,
          page: 1,
        },
        publicationState: "live",
        locale: ["en"],
      },
      {
        encodeValuesOnly: true, // prettify url
      }
    );
    var res = await fetch(
      `${strapi_end_point}/api/padhaai-programs?${queryForCourses}`
    );
    const JsonDecodeC = await res.json();
    setProgram(JsonDecodeC.data);
    const query = qs.stringify(
      {
        sort: ["id:asc"],
        fields: ["id", "slug", "name"],
        populate: {
          course_id: {
            fields: ["id"],
          },
        },
        pagination: {
          pageSize: 20,
          page: 1,
        },
        publicationState: "live",
        locale: ["en"],
      },
      {
        encodeValuesOnly: true, // prettify url
      }
    );
    const Response = await fetch(
      `${strapi_end_point}/api/course-categories?${query}`
    );
    const Data = await Response.json();
    setCategories(Data.data);
    const queryThreads = qs.stringify(
      {
        filters: {
          featured: true,
        },
        sort: ["id:desc"],
        fields: ["id", "slug", "title"],
        populate: {
          image: "*",
        },
        pagination: {
          pageSize: 50,
          page: 1,
        },
        publicationState: "live",
        locale: ["en"],
      },
      {
        encodeValuesOnly: true, // prettify url
      }
    );
    const ResponseThreads = await fetch(
      `${strapi_end_point}/api/bits-threads?${queryThreads}`
    );
    const DataTHreads = await ResponseThreads.json();
    setThreads(DataTHreads.data);
    const queryCourses = qs.stringify(
      {
        sort: ["id:desc"],
        fields: ["id"],
        populate: {
          course: {
            fields: ["slug", "title", "offerbyName"],
            populate: {
              cover: "*",
              course_reviews: {
                fields: ["id", "rating"],
              },
            },
          },
        },
        pagination: {
          pageSize: 10,
          page: 1,
        },
        publicationState: "live",
        locale: ["en"],
      },
      {
        encodeValuesOnly: true, // prettify url
      }
    );
    const ResponseCourses = await fetch(
      `${strapi_end_point}/api/professional-courses?${queryCourses}`
    );
    const DataCourses = await ResponseCourses.json();
    setCourses(DataCourses.data);
    //
    const queryHonHourCourses = qs.stringify(
      {
        sort: ["id:desc"],
        fields: ["id"],
        populate: {
          course: {
            fields: ["slug", "title", "offerbyName"],
            populate: {
              cover: "*",
              course_reviews: {
                fields: ["id", "rating"],
              },
            },
          },
          courseflix_hour_course_views: {
            fields: ["id"],
          },
        },
        pagination: {
          pageSize: 10,
          page: 1,
        },
        publicationState: "live",
        locale: ["en"],
      },
      {
        encodeValuesOnly: true, // prettify url
      }
    );
    const ResponseOneHourCourses = await fetch(
      `${strapi_end_point}/api/courseflix-hour-courses?${queryHonHourCourses}`
    );
    const DataOneHourCourses = await ResponseOneHourCourses.json();
    setOneHourCourses(DataOneHourCourses.data);
    const queryCreativeExpert = qs.stringify(
      {
        sort: ["id:desc"],
        fields: ["id", "bio"],
        populate: {
          user: {
            fields: ["username"],
            populate: {
              profile_id: {
                fields: ["id", "first_name", "last_name"],
                populate: {
                  avatar: "*",
                  cover: "*",
                },
              },
            },
          },
        },
        pagination: {
          pageSize: 10,
          page: 1,
        },
        publicationState: "live",
        locale: ["en"],
      },
      {
        encodeValuesOnly: true, // prettify url
      }
    );
    const ResponseExperts = await fetch(
      `${strapi_end_point}/api/course-creative-experts?${queryCreativeExpert}`
    );
    const DataExperts = await ResponseExperts.json();
    setExperts(DataExperts.data);
    setLoading(false);
  };

  const captureView = async (id) => {
    try {
      const Postdata = {
        data: {
          courseflix_hour_course: id,
        },
      };
      if (checkLogin()) {
        const user = JSON.parse(getUser());
        const queryCreativeExpert = qs.stringify(
          {
            filters: {
              user: user.id,
              courseflix_hour_course: id,
            },
            fields: ["id"],
            publicationState: "live",
            locale: ["en"],
          },
          {
            encodeValuesOnly: true, // prettify url
          }
        );
        const ResponseExperts = await fetch(
          `${strapi_end_point}/api/courseflix-hour-course-views?${queryCreativeExpert}`
        );
        if (ResponseExperts.data.length == 0) {
          data.data["user"] = user.id;
        } else {
          return false;
        }
      }
      const ResponseExperts = await fetch(
        `${strapi_end_point}/api/courseflix-hour-course-views`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(Postdata),
        }
      );
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getInitData();
  }, []);

  return (
    <>
      <Head>
        <title>Courseflix</title>
      </Head>
      {!isLoading ? (
        <section id="courseflix" className="pb-20 bg-black text-white">
          <div className="absolute top-0 inset-x-0 w-full h-[500px] courseflix-header">
            {/* <div className="absolute top-0 inset-x-0 z-10 px-4 py-4">
              <a className="font-[600] text-[20px] text-white">
                <BackButton /> <span className="ml-3">Courseflix</span>
              </a>
            </div> */}
            <div className="max-w-screen-xl mx-auto px-4 md:px-6 lg:px-10 pt-40">
              <h2 className="text-[40px] lg:text-[50px] font-semibold leading-[41px] lg:leading-[51px] max-w-[400px] lg:max-w-[420px]">
                <span className="text-primary">Coolfluence</span> the world
              </h2>
              <div className="relative w-full max-w-[660px] mt-6">
                <input
                  value={query}
                  onChange={(e) => {
                    setQuery(q.target.value);
                  }}
                  onKeyDown={handleKeyDown}
                  className="rounded-md bg-white py-3 px-4 text-[#cccccc] w-full"
                  placeholder="What do you want to learn?"
                />
                <i className="fa-regular text-black fa-magnifying-glass absolute right-4 top-1/2 -translate-y-1/2"></i>
              </div>
              <div className="flex items-center gap-3 flex-wrap mt-16">
                <h6 className="text-sm">Trending:</h6>
                {tags.map((tag, index) => {
                  return (
                    <a
                      key={index}
                      onClick={() => {
                        window.location.href =
                          "https://padhaai.gurucool.xyz/courses?q=" + tag;
                      }}
                    >
                      <div className="bg-[#443813] text-sm rounded-[4px] px-2">
                        {tag}
                      </div>
                    </a>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="w-full h-[500px]" />
          <div className="max-w-screen-xl mx-auto lg:px-10 pl-4 pt-10 mt-5">
            <h3 className="font-medium text-[24px]">Most Popular</h3>
            <div className="flex gap-3 overflow-x-scroll scrollbar-hide">
              {programs &&
                programs.length > 0 &&
                programs.map((prg, index) => {
                  if (!prg.attributes.featured) {
                    return;
                  }
                  return (
                    <div key={index} className="w-1/2 min-w-[225px]">
                      <div className="rounded-[11px] p-[10px]">
                        <Image
                          loader={myLoader}
                          src={
                            prg.attributes.image.data != null
                              ? prg.attributes.image.data.attributes.url
                              : Course1
                          }
                          alt=""
                          className="rounded-[6px]"
                          width={480}
                          height={336}
                        />
                        <div className="py-3">
                          <a
                            href={`https://padhaai.gurucool.xyz/programs/${prg.id}`}
                          >
                            {" "}
                            <h3 className="font-[500]">
                              {prg.attributes.title}
                            </h3>
                          </a>
                          <p className="text-primary text-sm mt-1">
                            {prg.attributes.by}
                          </p>
                          <div className="flex items-center gap-4 mt-2">
                            {prg.attributes.padhaai_program_courses.data
                              .length > 0 && (
                              <span className="bg-[#443813] rounded-[4px] px-2 text-sm">
                                {
                                  prg.attributes.padhaai_program_courses.data
                                    .length
                                }{" "}
                                Courses
                              </span>
                            )}
                            <span className="text-white text-sm">
                              {prg.attributes.is_free
                                ? "FREE"
                                : "Rs. " + prg.attributes.price}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
          <div className="max-w-screen-xl mx-auto lg:px-10 pl-4 pt-10 mt-6">
            <h3 className="font-medium text-[24px]">1-hour courses for you</h3>
            <div className="flex gap-3 overflow-x-scroll scrollbar-hide mt-7">
              {oneHourCourses &&
                oneHourCourses.length > 0 &&
                oneHourCourses.map((courseTmp, index) => {
                  const course = courseTmp.attributes.course.data;
                  if(course==null){
                    return 
                  }
                  return (
                    <div key={index} className="w-1/2 min-w-[225px]">
                      <div className="rounded-[8px] p-[10px] border border-[#343434]">
                        <Image
                          loader={myLoader}
                          src={
                            course.attributes.cover.data != null
                              ? course.attributes.cover.data.attributes.url
                              : Course1
                          }
                          alt=""
                          className="rounded-[6px]"
                          width={480}
                          height={336}
                        />
                        <div className="py-3">
                          <a
                            onClick={async () => {
                              await captureView(courseTmp.id);
                              window.location.href = `https://course.gurucool.xyz/${course.attributes.slug}`;
                            }}
                          >
                            <h3 className="font-[500]">
                              {he.decode(course.attributes.title)}
                            </h3>
                          </a>
                          {course.attributes.offerbyName && (
                            <p className="text-primary text-sm mt-1">
                              {course.attributes.offerbyName}
                            </p>
                          )}
                          {courseTmp.attributes.courseflix_hour_course_views
                            .data.length > 0 && (
                            <div className="flex items-center gap-2 mt-2">
                              <i className="text-sm text-[#646464] fa-regular fa-play"></i>
                              <span className="text-sm text-[#646464]">
                                {
                                  courseTmp.attributes
                                    .courseflix_hour_course_views.data.length
                                }{" "}
                                Learners{" "}
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
          <div className="mt-20 max-w-screen-xl mx-auto pl-4 md:pl-6 lg:px-10 pb-10">
            <div className="flex items-center justify-between pr-4 md:pr-6 lg:pr-0">
              <h3 className="font-medium text-[24px]">Categories</h3>
              <a href="" className="text-primary">
                See All
              </a>
            </div>
            <div className="overflow-x-scroll scrollbar-hide mt-4">
              <div className="flex gap-3 lg:flex-wrap">
                {categories.map((category, index) => {
                  return (
                    <span
                      key={index}
                      className="bg-black min-w-fit border border-white rounded-full py-2 px-5"
                    >
                      <a href={`/courses?category=${category.attributes.slug}`}>
                        {htmlDecode(category.attributes.name)}
                      </a>
                    </span>
                  );
                })}
              </div>
            </div>
          </div>
          {/* <div className="bg-[#1f1e1e] pt-12 pb-20 mt-12 overflow-x-hidden">
            <div className="max-w-screen-xl mx-auto px-4 md:px-6 lg:px-10">
              <h3 className="font-medium text-[24px] text-center mb-6">
                Learn from the creative experts
              </h3>
              <div className="lg:w-fit lg:mx-auto">
                <Swiper
                  slidesPerView="auto"
                  allowTouchMove={true}
                  modules={[Pagination, Navigation]}
                  watchSlidesProgress
                  observer
                  observeParents
                  pagination={{
                    el: "#swiper-pagination.swiper-pagination",
                    clickable: true,
                  }}
                >
                  {experts &&
                    experts.map((expert, index) => {
                      if (expert.attributes.user.data == null) {
                        return;
                      }
                      return (
                        <SwiperSlide key={index}>
                          <div className="rounded-full w-[250px] h-[300px]">
                            <Image
                              width={225}
                              height={225}
                              alt=""
                              objectFit="cover"
                              loader={myLoader}
                              src={
                                expert.attributes.user.data.attributes
                                  .profile_id.data.attributes.avatar.data
                                  ?.attributes.url ?? teacher1
                              }
                              className="rounded-[225px]"
                            />
                            <div className="py-5 px-3">
                              <h5 className="infinite-wordSpace font-semibold leading-[18px]">
                                {
                                  expert.attributes.user.data.attributes
                                    .username
                                }
                              </h5>
                              <p className="mt-1 text-xs">
                                {expert.attributes.bio}
                              </p>
                            </div>
                          </div>
                        </SwiperSlide>
                      );
                    })}
                  <div id="swiper-pagination" className="swiper-pagination" />
                </Swiper>
              </div>
            </div>
          </div> */}
          <div className="max-w-screen-xl mx-auto pl-4 md:pl-6 lg:px-10 pt-6 pb-20 mt-12">
            <h3 className="text-[24px] font-medium">15-Min Courses for you</h3>
            <div className="flex gap-6 overflow-x-scroll scrollbar-hide mt-7">
              {threads &&
                threads.map((thread, index) => {
                  return (
                    <div
                      key={index}
                      className="relative rounded-[10px] min-w-[195px] min-h-[260px]"
                    >
                      <Image
                        width={195}
                        height={260}
                        loader={myLoader}
                        alt=""
                        objectFit="cover"
                        src={
                          thread.attributes.image.data.attributes.url ?? biology
                        }
                        className="absolute inset-0 rounded-[10px]"
                      />
                      <div className="h-[150px] rounded-b-[10px] bottom-gradientEffect w-full flex items-end absolute z-10 inset-x-0 bottom-0">
                        <a
                          href={`https://padhaai.gurucool.xyz/threads/${thread.attributes.slug}`}
                        >
                          <h5 className="py-5 px-2 font-semibold">
                            {thread.attributes.title}
                          </h5>
                        </a>
                      </div>
                    </div>
                  );
                })}

              {/* <div className="relative rounded-[10px] w-[195px] h-[260px]">
              <Image alt="" src={biology} className="absolute inset-0" />
              <div className="h-[150px] rounded-b-[10px] bottom-gradientEffect w-full flex items-end absolute z-10 inset-x-0 bottom-0">
                <h5 className="font-semibold py-5 px-2">Quantum Physics</h5>
              </div>
            </div> */}
            </div>
          </div>
          <div className="bg-primary pt-16 pb-20">
            <div className="max-w-screen-xl mx-auto px-4 md:px-6 lg:px-10">
              <div className="lg:flex w-full lg:justify-between">
                <div className="w-full text-center lg:text-left">
                  {/* <span className="text-[10px] lg:text-[20px]">Gurucool's choice</span> */}
                  <h3 className="text-[24px] lg:text-[36px] font-semibold text-black">
                    Explore Careers
                  </h3>
                  <p className="text-sm lg:text-base w-[90%] lg:w-full mx-auto mt-3">
                    Build career-relevant skills with courses from top educators
                  </p>
                  <span className="text-[12px] lg:text-base font-semibold text-black inline-block mt-4">
                    Find Your Next Role
                  </span>
                </div>
                <div className="w-full flex flex-col items-center lg:items-end gap-3 mt-7">
                  {programs &&
                    programs.length > 0 &&
                    programs.map((prg, index) => {
                      if (!prg.attributes.career_listed) {
                        return false;
                      }
                      return (
                        <div
                          key={index}
                          className="w-full max-w-[300px] text-center rounded-[10px] bg-white text-black py-3 font-semibold"
                        >
                          <a
                            href={`https://padhaai.gurucool.xyz/programs/${prg.id}`}
                          >
                            {prg.attributes.title}
                          </a>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
          <div className="mt-12 max-w-screen-xl mx-auto pl-4 md:pl-6 lg:px-10 pt-12 pb-20">
            <div className="flex items-baseline justify-between gap-10 pr-4 md:pr-6 lg:pr-0">
              <h3 className="font-medium text-[24px]">
                Professional Courses for you
              </h3>
              <a href="" className="text-primary min-w-fit">
                See All
              </a>
            </div>
            <div className="flex gap-3 overflow-x-scroll scrollbar-hide mt-7">
              {courses &&
                courses.map((courseD, index) => {
                  const course = courseD.attributes.course.data;
                  if(course==null){
                    return;
                  }
                  const reviews = course.attributes.course_reviews;
                  var total_review = 0;
                  var rating = 0;
                  if (reviews.data !== null && reviews.data.length > 0) {
                    total_review = reviews.data.length;
                    var rate = 0;
                    reviews.data.map((d) => {
                      rate = rate + d.attributes.rating;
                    });
                    if (total_review > 0) {
                      rating = Math.ceil(rate / total_review);
                    } else {
                      rating = 0;
                    }
                  }
                  return (
                    <div key={index} className="w-1/2 min-w-[225px]">
                      <div className="rounded-[11px] p-[10px]">
                        <Image
                          loader={myLoader}
                          src={
                            course.attributes.cover.data != null
                              ? course.attributes.cover.data.attributes.url
                              : Course1
                          }
                          alt=""
                          className="rounded-[6px]"
                          width={480}
                          height={336}
                        />
                        <div className="py-3">
                          <Link
                            href={`https://course.gurucool.xyz/${course.attributes.slug}`} passHref
                          >
                            <a>
                              <h3 className="font-[400] text-sm">
                                {he.decode(course.attributes.title)}
                              </h3>
                            </a>
                          </Link>
                          {course.attributes.offerbyName && (
                            <p className="text-primary text-[10px] mt-1">
                              {course.attributes.offerbyName}
                            </p>
                          )}
                          <div className="flex items-center gap-1 mt-2">
                            <i className="fa-solid fa-star text-[#818181] text-[10px]"></i>
                            <span className="text-[10px] text-[#818181]">
                              {rating} ({total_review})
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
          <div className="max-w-screen-xl mx-auto px-4 md:px-6 lg:px-10 mt-16">
            <div className="w-full rounded-[12px] radial-gradient flex flex-col gap-6 items-center text-center px-6 py-20">
              <h3 className="text-[24px] font-medium">
                See what's available now
              </h3>
              <Link href="/categories" passHref>
                <a className="inline-block border font-medium border-white mx-auto rounded-[8px] py-[10px] px-2">
                  Browse all categories
                </a>
              </Link>
            </div>
          </div>
        </section>
      ) : (
        <center className="h-screen bg-black relative">
          <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
            <div
              className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-primary border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
              role="status"
            >
              <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                Loading...
              </span>
            </div>
          </div>
        </center>
      )}
      <style global jsx>{`
        footer {
          display: none !important;
        }
      `}</style>
    </>
  );
};
export default CourseflixPage;
